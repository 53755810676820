@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {
  display: $type;
  @if ($align) {
    align-items: $align;
  }
  @if ($justify) {
    justify-content: $justify;
  }
  @if ($direction) {
    flex-direction: $direction;
  }
}

@mixin position(
  $pos: false,
  $top: false,
  $right: false,
  $bottom: false,
  $left: false,
  $z-index: false
) {
  @if ($pos) {
    position: $pos;
  }
  @if ($top) {
    top: $top;
  }
  @if ($right) {
    right: $right;
  }
  @if ($bottom) {
    bottom: $bottom;
  }
  @if ($left) {
    left: $left;
  }
  @if ($z-index) {
    z-index: $z-index;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  position: $pos;
  display: $display;
  content: $content;
}

@mixin breakpoint($point) {
  @if $point == big_mobile {
    @media (min-width: 30rem) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 46.5rem) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: 64rem) {
      @content;
    }
  } @else if $point == big_desktop {
    @media (min-width: 90rem) {
      @content;
    }
  } @else if $point == iphone_5 {
    @media only screen and (min-width: 320px) and (max-width: 340px) and (orientation: portrait) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, md_down, lg, xl, iphone_5";
  }
}
