// red //
$red_800: rgba(225, 94, 94, 0.1);
$red_560: #c83532;
$red_550: #cf2b31;
$red_540: #ce2726;
$red_400: #ce4d48;
$red_390: #e15e5e;
$red_380: #e36771;
$red_200: #db918b;
$red_110: #feefef;
$red_100: #fff3eb;

// orange //
$orange_500: #f0825a;
$orange_400: #e59066;
$orange_220: #fbdaa2;
$orange_200: #ffedcf;
$orange_100: #f5ede0;

// yellow //
$yellow_800: rgba(246, 210, 80, 0.2);
$yellow_600: #e1ab41;
$yellow_550: #eea034;
$yellow_500: #f6d250;

// green //
$green_800: rgba(93, 191, 125, 0.16);
$green_750: rgba(93, 191, 125, 0.3);
$green_600: #64ae67;
$green_550: #6ec195;
$green_500: #5dbf7d;
$green_490: #5ebe7d;
$green_300: #7dc869;
$green_200: #bbeebd;
$green_150: rgba(187, 238, 189, 0.9);
$green_125: #dcf0f1;
$green_120: #dbebeb;
$green_110: #ebf7ef;
$green_100: #f4fbf8;

$green_blue_500: #368091;
$green_blue_400: #3f97aa;

// blue //
$blue_870: rgba(90, 130, 230, 0.06);
$blue_800: rgba(90, 130, 230, 0.16);
$blue_700: rgba(90, 130, 230, 0.5);
$blue_500: #5a82e6;
$blue_300: #c8d1ed;
$blue_280: #deebfd;
$blue_260: #eef5fe;
$blue_200: #eff3ff;
$blue_150: #ebf2f4;
$blue_100: #f8f8f8;

// violet
$violet_800: rgba(123, 97, 255, 0.16);
$violet_600: #8255e7;
$violet_500: #7b61ff;
$violet_400: #736a9c;
$violet_300: #a458b7;

// pink
$pink_500: #ea548c;

// black //
$black_1000: #000000;
$black_550: rgba(0, 0, 0, 0.5);
$black_510: rgba(0, 0, 0, 0.15);
$black_450: #242424;
$black_390: #242a31;
$black_370: #2c3246;
$black_360: #2b3143;
$black_350: rgb(44, 50, 70);
$black_330: rgba(44, 50, 70, 0.8);
$black_320: rgba(44, 50, 70, 0.7);
$black_300: rgba(44, 50, 70, 0.5);
$black_280: rgba(43, 49, 67, 0.5);
$black_250: rgb(37, 37, 37, 0.5);
$black_100: rgba(44, 50, 70, 0.1);

// grey //
$grey_900: rgba(242, 244, 248, 0);
$grey_800: rgba(60, 60, 67, 0.36);
$grey_700: rgba(184, 184, 184, 0.2);
$grey_600: #828282;
$grey_500: #5f6062;
$grey_450: #737373;
$grey_410: rgba(196, 196, 196, 0.4);
$grey_400: rgba(238, 240, 245, 0.4);
$grey_350: rgba(201, 201, 201, 0.8);
$grey_300: #a9a9a9;
$grey_250: #b8b8b8;
$gray_230: #c4c4c4;
$grey_220: #d0d0d0;
$grey_200: #c5c9d1;
$grey_180: #cdcdcd;
$grey_140: #e5e3de;
$grey_120: rgba(255, 255, 255, 0.8);
$grey_100: #e0e3ea;

// white //
$white_1000: #ffffff;
$white_650: #f2f4f8;
$white_600: #f9f9f9;
$white_500: #f7f7f8;
$white_450: #f5f6fa;
$white_400: #f5f4f2;
$white_390: #f4f4f4;

$pdf_blue: #368091;
