@import 'resetCss';
@import 'colors';
@import 'fonts';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  box-sizing: border-box;
  height: 100%;
  color: $black_370;
  font-family: $manrope;
}

#solid-payment-form-iframe {
  width: 100%;
  min-height: 190px;
  border: none;
}

.reviews_slider {
  .swiper-pagination {
    position: static;
    margin-top: 16px;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: transparent;
    border: 1px solid $black_1000;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: $black_1000;
  }
}
