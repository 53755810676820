@import '@common/styles/colors';
@import '@common/styles/mixins';

.wrapper {
  @include flex($align: center, $justify: center);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: $black_1000;
}
